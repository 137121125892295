import {
  menuActionTypes,
  OpenLoginMenuAction,
  CloseLoginMenuAction,
  SetLoginMenuScreenAction,
  OpenNavMenuAction,
  CloseNavMenuAction,
  CloseAllMenusAction,
  loginMenuScreens,
  OpenSearchMenuAction,
  CloseSearchMenuAction,
  OpenUpdateProfileMenuAction,
  CloseUpdateProfileMenuAction,
} from "@/fe-core/meta/types/menus";

export const openLoginMenu = (): OpenLoginMenuAction => ({
  type: menuActionTypes.OPEN_LOGIN_MENU,
});

export const closeLoginMenu = (): CloseLoginMenuAction => ({
  type: menuActionTypes.CLOSE_LOGIN_MENU,
});

export const setLoginMenuScreen = (
  payload: loginMenuScreens
): SetLoginMenuScreenAction => ({
  type: menuActionTypes.SET_LOGIN_MENU_SCREEN,
  payload,
});

export const openNavMenu = (): OpenNavMenuAction => ({
  type: menuActionTypes.OPEN_NAV_MENU,
});

export const closeNavMenu = (): CloseNavMenuAction => ({
  type: menuActionTypes.CLOSE_NAV_MENU,
});

export const openSearchMenu = (): OpenSearchMenuAction => ({
  type: menuActionTypes.OPEN_SEARCH_MENU,
});
export const closeSearchMenu = (): CloseSearchMenuAction => ({
  type: menuActionTypes.CLOSE_SEARCH_MENU,
});

export const openUpdateProfileModalMenu = (): OpenUpdateProfileMenuAction => ({
  type: menuActionTypes.OPEN_UPDATE_PROFILE_MENU,
});
export const closeUpdateProfileModalMenu = (): CloseUpdateProfileMenuAction => ({
  type: menuActionTypes.CLOSE_UPDATE_PROFILE_MENU,
});

export const closeAllMenus = (): CloseAllMenusAction => ({
  type: menuActionTypes.CLOSE_ALL_MENUS,
});
