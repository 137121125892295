import { MAX_GAMES_PER_PAGE } from "@/config/casino";
import {
  CasinoGamesPayload,
  CasinoPagination,
} from "@/fe-core/meta/types/casino";
import { ICasinoGroupViewModel } from "../meta/interfaces/casino";

interface BuildCasinoQueryStringParams {
  fields?: string[];
  dataSource?: string | null;
  filters?: string[] | null;
  pagination?: CasinoPagination | null;
  expand?: string[] | null;
  country?: string | null;
  language?: string | null;
  platform?: string | null;
  sortedField?: string | null;
  orderBy?: string | null;
  keyword?: string | null;
}

export function buildCasinoQueryString({
  fields,
  dataSource = null,
  filters = null,
  pagination = null,
  expand = null,
  country = null,
  language = null,
  platform = null,
  sortedField = null,
  orderBy = null,
  keyword
}: BuildCasinoQueryStringParams): string {
  const queryParams = new URLSearchParams();

  if (dataSource) {
    queryParams.append("datasource", dataSource);
  }

  if (expand && expand.length) {
    queryParams.append("expand", expand.join(","));
  }

  if (fields && fields.length) {
    queryParams.append("fields", fields.join(","));
  }

  if (filters && filters.length) {
    queryParams.append("filter", filters.join(","));
  }

  if (pagination) {
    const paginationString = `offset=${pagination.offset},limit=${pagination.limit}`;
    queryParams.append("pagination", paginationString);
  }

  // if (country) {
  //   queryParams.append("selectedCountryCode", country);
  // }
  if (language) {
    queryParams.append("language", language);
  }
  if (platform) {
    queryParams.append("platform", platform);
  }

  if (sortedField) {
    queryParams.append("sortedField", sortedField);
  }

  if (orderBy) {
    queryParams.append("orderBy", orderBy);
  }
 
  if (keyword) {
    queryParams.append("keyword", keyword);
  }

  const queryString = queryParams.toString();

  return queryString ? `?${queryString}` : "";
}

export function buildCasinoSingleVendorQueryString(
  expand: string[] = [],
  fields: string[] = [],
  platform?: string,
  locale?: string,
  gameFields: string[] = [],
  dataSource?: string | null
): string {
  const queryParams = new URLSearchParams();

  if (expand && expand.length) {
    queryParams.append("expand", expand.join(","));
  }

  if (platform) {
    queryParams.append("platform", platform);
  }

  if (fields && fields.length) {
    let queryFields = "";

    for (let i = 0; i < fields.length; i++) {
      if (i !== 0) {
        queryFields += ",";
      }

      if (expand.includes(fields[i])) {
        queryFields += `${fields[i]}(${gameFields.join(",")})`;
      } else {
        queryFields += fields[i];
      }
    }

    queryParams.append("fields", queryFields);
  }

  if (dataSource) {
    queryParams.append("datasource", dataSource);
  }
  if (locale) {
    queryParams.append("language", locale);
  }
  //queryParams.append("selectedCountryCode", "RO");

  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export function buildCasinoGroupsQueryString(
  expand: string[] = [],
  fields: string[] = [],
  platform?: string,
  gameFields: string[] = [],
  locale?: string,
  filters: string[] = [],
  country?: string | null,
  pagination?: CasinoPagination,
  dataSource?: string | null,
  sortedField?: string | null,
  orderBy?: string | null
): string {
  const queryParams = new URLSearchParams();

  if (expand && expand.length) {
    queryParams.append("expand", expand.join(","));
  }

  if (platform) {
    queryParams.append("platform", platform);
  }

  if (sortedField) {
    queryParams.append("sortedField", sortedField);
  }

  if (orderBy) {
    queryParams.append("orderBy", orderBy);
  }

  if (pagination && pagination.limit) {
    const games = `${
      pagination?.offset > 0 ? `offset=${pagination.offset},` : ""
    }limit=${pagination.limit}`;
    queryParams.append("pagination", games);
  }

  if (fields && fields.length) {
    let queryFields = "";

    for (let i = 0; i < fields.length; i++) {
      if (i !== 0) {
        queryFields += ",";
      }

      if (expand.includes(fields[i])) {
        queryFields += `${fields[i]}(${gameFields.join(",")})`;
      } else {
        queryFields += fields[i];
      }
    }

    queryParams.append("fields", queryFields);
  }

  if (dataSource) {
    queryParams.append("datasource", dataSource);
  }
  if (locale) {
    queryParams.append("language", locale);
  }
  // if (country) {
  //   queryParams.append("selectedCountryCode", country);
  // }

  // queryParams.append("selectedCountryCode", "JP");
  if (filters && filters.length) {
    queryParams.append("filter", filters.join(","));
  }
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export function getDefaultCasinoGamesPayload(): CasinoGamesPayload {
  return {
    filters: [],
    platform: "",
    pagination: {
      limit: MAX_GAMES_PER_PAGE,
      offset: 0,
    },
  };
}

export function getVendorIdFilterString(
  vendorId: (number | string)[] | number,
  parent?: string | null
): string {
  const entry =
    typeof vendorId !== "undefined"
      ? typeof vendorId === "number"
        ? `vendor(id=${vendorId})`
        : vendorId.map((_vendorId) => `vendor(id=${_vendorId})`).join(",")
      : "";
  return parent ? `${parent}(${entry})` : entry;
}

export function getCategoryIdFilterString(categoryId: string): string {
  return typeof categoryId !== "undefined"
    ? `categories(id=${categoryId})`
    : "";
}

export function getGroupsIdFilterString(groupId: string): string {
  return typeof groupId !== "undefined" ? `groups=${groupId}` : "";
}

export function getNameFilterString(name: string): string {
  return name ? `name=${name}` : ``;
}

export function getGameFilterString(keyword: string): string {
  return keyword ? `keyword=${keyword}` : ``;
}

export function getVendorNameFilterString(name: string): string {
  return name ? `vendor(name=${name})` : ``;
}

export function getGameDesktopSupportFilterString(
  supportsDesktop: boolean
): string {
  return supportsDesktop !== null || supportsDesktop !== undefined
    ? `supportsDesktop=${supportsDesktop}`
    : ``;
}

export function getGameMobileSupportFilterString(
  supportsMobile: boolean
): string {
  return supportsMobile !== null || supportsMobile !== undefined
    ? `supportsMobile=${supportsMobile}`
    : ``;
}

export function getGamesPlatform(isMobileOrTablet: boolean | null): string {
  return isMobileOrTablet ? "Android" : "PC";
}

export function filterGroups(
  groups: ICasinoGroupViewModel[] | null,
  numberOfGamesToCheck: number
): ICasinoGroupViewModel[] {
  if (!groups) return [];

  const seenGames = new Set();

  return groups.map((group) => {
    if (group?.slug === "favorites" || group?.slug === "recent-games")
      return group;

    const games = group?.featuredGames || [];
    const uniqueGames: any[] = [];
    const totalFeaturedGames =
      (process.env.NEXT_PUBLIC_HAS_FIXED_GAMES_PER_ROW === "YES"
        ? numberOfGamesToCheck
        : group?.numberOfGamesPerRow || numberOfGamesToCheck) *
      (group?.numberOfRows || 1);

    for (const game of games) {
      if (uniqueGames.length >= totalFeaturedGames) break; // Stop once we have enough unique games
      if (!seenGames.has(game.internalId)) {
        seenGames.add(game.internalId);
        uniqueGames.push(game);
      }
    }

    return { ...group, featuredGames: uniqueGames };
  });
}
