import { IErrorPayload, IStatus } from "../interfaces/root";
import {
  ICasinoVendorDetailsPayload,
  ICasinoVendorMoreGamesPayload,
  ICasinoVendorsPayload,
  ICasinoVendorViewModel,
  IVendorDetailsViewModel,
  IVendorsRequestPayload,
} from "../interfaces/casinoVendors";

export enum casinoVendorsActionTypes {
  GET_VENDOR_DETAILS_REQUEST = "GET_VENDOR_DETAILS_REQUEST",
  GET_VENDOR_DETAILS_SUCCESS = "GET_VENDOR_DETAILS_SUCCESS",
  GET_VENDOR_DETAILS_FAILURE = "GET_VENDOR_DETAILS_FAILURE",
  GET_VENDORS_REQUEST = "GET_VENDORS_REQUEST",
  GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS",
  GET_VENDORS_FAILURE = "GET_VENDORS_FAILURE",
  GET_SINGLE_CASINO_VENDOR_REQUEST = "GET_SINGLE_CASINO_VENDOR_REQUEST",
  GET_SINGLE_CASINO_VENDOR_SUCCESS = "GET_SINGLE_CASINO_VENDOR_SUCCESS",
  GET_SINGLE_CASINO_VENDOR_FAILURE = "GET_SINGLE_CASINO_VENDOR_FAILURE",
  GET_CASINO_VENDOR_MORE_GAMES_REQUEST = "GET_CASINO_VENDOR_MORE_GAMES_REQUEST",
  GET_CASINO_VENDOR_MORE_GAMES_SUCCESS = "GET_CASINO_VENDOR_MORE_GAMES_SUCCESS",
  GET_CASINO_VENDOR_MORE_GAMES_FAILURE = "GET_CASINO_VENDOR_MORE_GAMES_FAILURE",

  GET_SUB_VENDORS_REQUEST = "GET_SUB_VENDORS_REQUEST",
  GET_SUB_VENDORS_SUCCESS = "GET_SUB_VENDORS_SUCCESS",
  GET_SUB_VENDORS_FAILURE = "GET_SUB_VENDORS_FAILURE",
  GET_SUB_SINGLE_CASINO_VENDOR_REQUEST = "GET_SUB_SINGLE_CASINO_VENDOR_REQUEST",
  GET_SUB_SINGLE_CASINO_VENDOR_SUCCESS = "GET_SUB_SINGLE_CASINO_VENDOR_SUCCESS",
  GET_SUB_SINGLE_CASINO_VENDOR_FAILURE = "GET_SUB_SINGLE_CASINO_VENDOR_FAILURE",
  GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST = "GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST",
  GET_SUB_CASINO_VENDOR_MORE_GAMES_SUCCESS = "GET_SUB_CASINO_VENDOR_MORE_GAMES_SUCCESS",
  GET_SUB_CASINO_VENDOR_MORE_GAMES_FAILURE = "GET_SUB_CASINO_VENDOR_MORE_GAMES_FAILURE",
}

export type GetVendorDetailsRequestAction = {
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_REQUEST;
  payload: ICasinoVendorDetailsPayload;
};

export type GetVendorDetailsSuccessAction = {
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_SUCCESS;
  payload: IVendorDetailsViewModel;
};

export type GetVendorDetailsFailureAction = {
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_FAILURE;
  payload: IErrorPayload;
};

export type GetVendorsRequestAction = {
  type: casinoVendorsActionTypes.GET_VENDORS_REQUEST;
  payload: IVendorsRequestPayload;
};

export type GetVendorsSuccessAction = {
  type: casinoVendorsActionTypes.GET_VENDORS_SUCCESS;
  payload: ICasinoVendorsPayload;
};

export type GetVendorsFailureAction = {
  type: casinoVendorsActionTypes.GET_VENDORS_FAILURE;
  payload: IErrorPayload;
};


export type GetSingleCasinoVendorRequestAction = {
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_REQUEST;
  payload: SingleCasinoVendorPayload;
};

export type GetSingleCasinoVendorSuccessAction = {
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_SUCCESS;
  payload: ICasinoVendorViewModel;
};

export type GetSingleCasinoVendorFailureAction = {
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_FAILURE;
  payload: IErrorPayload;
};

export type GetCasinoVendorMoreGamesRequestAction = {
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_REQUEST;
  payload: CasinoVendorPayload;
};

export type GetCasinoVendorMoreGamesSuccessAction = {
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_SUCCESS;
  payload: ICasinoVendorMoreGamesPayload;
};

export type GetCasinoVendorMoreGamesFailureAction = {
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_FAILURE;
  payload: IErrorPayload;
};

//sub-vendor
export type GetSubVendorsRequestAction = {
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_REQUEST;
  payload: string;
};

export type GetSubVendorsSuccessAction = {
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_SUCCESS;
  payload: ICasinoVendorsPayload;
};

export type GetSubVendorsFailureAction = {
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_FAILURE;
  payload: IErrorPayload;
};


export type GetSubSingleCasinoVendorRequestAction = {
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_REQUEST;
  payload: SingleCasinoVendorPayload;
};

export type GetSubSingleCasinoVendorSuccessAction = {
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_SUCCESS;
  payload: ICasinoVendorViewModel;
};

export type GetSubSingleCasinoVendorFailureAction = {
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_FAILURE;
  payload: IErrorPayload;
};

export type GetSubCasinoVendorMoreGamesRequestAction = {
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST;
  payload: CasinoVendorPayload;
};

export type GetSubCasinoVendorMoreGamesSuccessAction = {
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_SUCCESS;
  payload: ICasinoVendorViewModel;
};

export type GetSubCasinoVendorMoreGamesFailureAction = {
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_FAILURE;
  payload: IErrorPayload;
};

export type CasinoVendorsActions =
  | GetVendorsRequestAction
  | GetVendorsSuccessAction
  | GetVendorsFailureAction
  | GetSingleCasinoVendorRequestAction
  | GetSingleCasinoVendorSuccessAction
  | GetSingleCasinoVendorFailureAction
  | GetCasinoVendorMoreGamesRequestAction
  | GetCasinoVendorMoreGamesSuccessAction
  | GetCasinoVendorMoreGamesFailureAction
  | GetSubVendorsRequestAction
  | GetSubVendorsSuccessAction
  | GetSubVendorsFailureAction
  | GetSubSingleCasinoVendorRequestAction
  | GetSubSingleCasinoVendorSuccessAction
  | GetSubSingleCasinoVendorFailureAction
  | GetSubCasinoVendorMoreGamesRequestAction
  | GetSubCasinoVendorMoreGamesSuccessAction
  | GetSubCasinoVendorMoreGamesFailureAction
  | GetVendorDetailsSuccessAction
  | GetVendorDetailsFailureAction
  | GetVendorDetailsRequestAction;

export type CasinoVendorsState = {
  pending: boolean;
  singleVendorPending: boolean;
  vendorDetailsMoreGamesPending: boolean;
  vendors: ICasinoVendorViewModel[] | null;
  subVendors: ICasinoVendorViewModel[] | null;
  vendor: ICasinoVendorViewModel | null;
  subVendor: ICasinoVendorViewModel | null;
  vendorDetails: ICasinoVendorViewModel | null;
  vendorDetailsPending: boolean;
  vendorDetailsStatus: IStatus | null;
  totalCount: number;
  filters: string[];
};

export type CasinoPagination = {
  offset: number;
  limit: number;
};

export type SingleCasinoVendorPayload = {
  slug: string;
  platform?: string;
};

export type CasinoVendorPayload = {
  dataSource?: string;
  slug: string;
  pagination: CasinoPagination;
  platform?: string;
};
