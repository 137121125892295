import {
  GameTooltip,
  IMobileGamePlayModalDetailsModel,
  ICasinoCategoriesPayload,
  ICasinoGameViewModel,
  ICasinoGamesPayload,
  ICasinoGroupViewModel,
  ICasinoLoadMoreGamesSuccessPayload,
} from "@/fe-core/meta/interfaces/casino";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  casinoActionTypes,
  CasinoGamesPayload,
  CasinoGroupsPayload,
  CasinoMoreGamesGroupsPayload,
  CasinoSingleGamePayload,
  GetCasinoGroupsFailureAction,
  GetCasinoGroupsRequestAction,
  GetCasinoGroupsSuccessAction,
  GetCasinoPopularGamesGroupRequestAction,
  GetCasinoPopularGamesGroupSuccessAction,
  GetCasinoPopularGamesGroupFailureAction,
  GetCasinoAuditGroupRequestAction,
  GetCasinoAuditGroupSuccessAction,
  GetCasinoAuditGroupFailureAction,
  GetCasinoMoreGamesGroupsFailureAction,
  GetCasinoMoreGamesGroupsRequestAction,
  GetCasinoMoreGamesGroupsSuccessAction,
  GetCategoriesFailureAction,
  GetCategoriesRequestAction,
  GetCategoriesSuccessAction,
  GetGamesFailureAction,
  GetGamesRequestAction,
  GetGamesSuccessAction,
  GetLiveCasinoGroupsFailureAction,
  GetLiveCasinoGroupsRequestAction,
  GetLiveCasinoGroupsSuccessAction,
  GetMoreGamesRequestAction,
  GetSingleGameRequestAction,
  GetSingleGameFailureAction,
  GetSingleGameSuccessAction,
  SetGameCardTooltipAction,
  SetMobileGamePlayModalDetailsAction,
  SetShowGameAlterModalAction,
  SetHideGameAlterModalAction,
  SetShowGameAlterModalPayload,
  GetLiveCasinoMoreGamesGroupsRequestAction,
  GetLiveCasinoMoreGamesGroupsSuccessAction,
  GetLiveCasinoMoreGamesGroupsFailureAction,
  GetCasinoHomeGroupsRequestAction,
  GetCasinoHomeGroupsSuccessAction,
  GetCasinoHomeGroupsFailureAction,
  GetLiveCasinoPopularGamesGroupRequestAction,
  GetLiveCasinoPopularGamesGroupSuccessAction,
  GetLiveCasinoPopularGamesGroupFailureAction,
  CasinoAllGamesPayload,
  GetCasinoAllGamesRequestAction,
  GetCasinoAllGamesSuccessAction,
  GetCasinoAllGamesFailureAction,
  SetCasinoAllGamesGroupAction,
  SetShowGameFullScreenButtonAction,
  SetHideGameFullScreenButtonAction,
  ToggleGameFullScreenAction,
} from "@/fe-core/meta/types/casino";

export const setShowGameAlterModal = (payload: SetShowGameAlterModalPayload): SetShowGameAlterModalAction => ({
  type: casinoActionTypes.SET_SHOW_GAME_ALTER_MODAL,
  payload
});

export const setHideGameAlterModal = (): SetHideGameAlterModalAction => ({
  type: casinoActionTypes.SET_HIDE_GAME_ALTER_MODAL,
});

export const getCategoriesRequest = (): GetCategoriesRequestAction => ({
  type: casinoActionTypes.GET_CATEGORIES_REQUEST,
});

export const getCategoriesSuccess = (
  payload: ICasinoCategoriesPayload
): GetCategoriesSuccessAction => ({
  type: casinoActionTypes.GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoriesFailure = (
  payload: IErrorPayload
): GetCategoriesFailureAction => ({
  type: casinoActionTypes.GET_CATEGORIES_FAILURE,
  payload,
});

export const getGamesRequest = (
  payload: CasinoGamesPayload
): GetGamesRequestAction => ({
  type: casinoActionTypes.GET_GAMES_REQUEST,
  payload,
});

export const getMoreGamesRequest = (
  payload: CasinoGamesPayload
): GetMoreGamesRequestAction => ({
  type: casinoActionTypes.GET_MORE_GAMES_REQUEST,
  payload,
});

export const getSingleGameRequest = (
  payload: CasinoSingleGamePayload
): GetSingleGameRequestAction => ({
  type: casinoActionTypes.GET_SINGLE_GAME_REQUEST,
  payload,
});

export const getSingleGameSuccess = (
  payload: ICasinoGameViewModel
): GetSingleGameSuccessAction => ({
  type: casinoActionTypes.GET_SINGLE_GAME_SUCCESS,
  payload,
});

export const getSingleGameFailure = (
  payload: IErrorPayload
): GetSingleGameFailureAction => ({
  type: casinoActionTypes.GET_SINGLE_GAME_FAILURE,
  payload,
});

export const getGamesSuccess = (
  payload: ICasinoGamesPayload
): GetGamesSuccessAction => ({
  type: casinoActionTypes.GET_GAMES_SUCCESS,
  payload,
});

export const getGamesFailure = (
  payload: IErrorPayload
): GetGamesFailureAction => ({
  type: casinoActionTypes.GET_GAMES_FAILURE,
  payload,
});

export const getCasinoGroupsRequest = (
  payload: CasinoGroupsPayload
): GetCasinoGroupsRequestAction => ({
  type: casinoActionTypes.GET_CASINO_GROUPS_REQUEST,
  payload,
});

export const getCasinoGroupsSuccess = (
  payload: ICasinoGroupViewModel[]
): GetCasinoGroupsSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_GROUPS_SUCCESS,
  payload,
});

export const getCasinoGroupsFailure = (
  payload: IErrorPayload
): GetCasinoGroupsFailureAction => ({
  type: casinoActionTypes.GET_CASINO_GROUPS_FAILURE,
  payload,
});

export const getCasinoPopularGamesGroupRequest = (
  payload: CasinoGroupsPayload
): GetCasinoPopularGamesGroupRequestAction => ({
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_REQUEST,
  payload,
});

export const getCasinoPopularGamesGroupSuccess = (
  payload: ICasinoGroupViewModel[]
): GetCasinoPopularGamesGroupSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_SUCCESS,
  payload,
});

export const getCasinoPopularGamesGroupFailure = (
  payload: IErrorPayload
): GetCasinoPopularGamesGroupFailureAction => ({
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_FAILURE,
  payload,
});

export const getLiveCasinoPopularGamesGroupRequest = (
  payload: CasinoGroupsPayload
): GetLiveCasinoPopularGamesGroupRequestAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST,
  payload,
});

export const getLiveCasinoPopularGamesGroupSuccess = (
  payload: ICasinoGroupViewModel[]
): GetLiveCasinoPopularGamesGroupSuccessAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_SUCCESS,
  payload,
});

export const getLiveCasinoPopularGamesGroupFailure = (
  payload: IErrorPayload
): GetLiveCasinoPopularGamesGroupFailureAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_FAILURE,
  payload,
});

export const getCasinoAuditGroupRequest = (
  payload: CasinoGroupsPayload
): GetCasinoAuditGroupRequestAction => ({
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_REQUEST,
  payload,
});

export const getCasinoAuditGroupSuccess = (
  payload: ICasinoGroupViewModel[]
): GetCasinoAuditGroupSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_SUCCESS,
  payload,
});

export const getCasinoAuditGroupFailure = (
  payload: IErrorPayload
): GetCasinoAuditGroupFailureAction => ({
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_FAILURE,
  payload,
});

export const getCasinoMoreGamesGroupsRequest = (
  payload: CasinoMoreGamesGroupsPayload
): GetCasinoMoreGamesGroupsRequestAction => ({
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST,
  payload,
});

export const getCasinoMoreGamesGroupsSuccess = (
  payload: ICasinoLoadMoreGamesSuccessPayload
): GetCasinoMoreGamesGroupsSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_SUCCESS,
  payload,
});

export const getCasinoMoreGamesGroupsFailure = (
  payload: IErrorPayload
): GetCasinoMoreGamesGroupsFailureAction => ({
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_FAILURE,
  payload,
});

export const getLiveCasinoMoreGamesGroupsRequest = (
  payload: CasinoMoreGamesGroupsPayload
): GetLiveCasinoMoreGamesGroupsRequestAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST,
  payload,
});

export const getLiveCasinoMoreGamesGroupsSuccess = (
  payload: ICasinoLoadMoreGamesSuccessPayload
): GetLiveCasinoMoreGamesGroupsSuccessAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_SUCCESS,
  payload,
});

export const getLiveCasinoMoreGamesGroupsFailure = (
  payload: IErrorPayload
): GetLiveCasinoMoreGamesGroupsFailureAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_FAILURE,
  payload,
});

export const getLiveCasinoGroupsRequest = (
  payload: CasinoGroupsPayload
): GetLiveCasinoGroupsRequestAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_REQUEST,
  payload,
});

export const getLiveCasinoGroupsSuccess = (
  payload: ICasinoGroupViewModel[]
): GetLiveCasinoGroupsSuccessAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_SUCCESS,
  payload,
});

export const getLiveCasinoGroupsFailure = (
  payload: IErrorPayload
): GetLiveCasinoGroupsFailureAction => ({
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_FAILURE,
  payload,
});

export const setGameCardTooltip = (
  payload: GameTooltip | null
): SetGameCardTooltipAction => ({
  type: casinoActionTypes.SET_GAME_CARD_TOOLTIP,
  payload,
});

export const setMobileGamePlayModalDetails = (
  payload: IMobileGamePlayModalDetailsModel
): SetMobileGamePlayModalDetailsAction => ({
  type: casinoActionTypes.SET_MOBILE_GAME_PLAY_MODAL_DETAILS,
  payload,
});

//home datasource
export const getCasinoHomeGroupsRequest = (
  payload: CasinoGroupsPayload
): GetCasinoHomeGroupsRequestAction => ({
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_REQUEST,
  payload,
});

export const getCasinoHomeGroupsSuccess = (
  payload: ICasinoGroupViewModel[]
): GetCasinoHomeGroupsSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_SUCCESS,
  payload,
});

export const getCasinoHomeGroupsFailure = (
  payload: IErrorPayload
): GetCasinoHomeGroupsFailureAction => ({
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_FAILURE,
  payload,
});

export const getCasinoAllGamesRequest = (
  payload: CasinoAllGamesPayload
): GetCasinoAllGamesRequestAction => ({
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_REQUEST,
  payload,
});

export const getCasinoAllGamesSuccess = (
  payload: ICasinoGamesPayload
): GetCasinoAllGamesSuccessAction => ({
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_SUCCESS,
  payload,
});

export const getCasinoAllGamesFailure = (
  payload: IErrorPayload
): GetCasinoAllGamesFailureAction => ({
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_FAILURE,
  payload,
});

export const setCasinoAllGamesGroup = (
  payload: ICasinoGamesPayload
): SetCasinoAllGamesGroupAction => ({
  type: casinoActionTypes.SET_ALL_GAMES_GROUP,
  payload,
});

export const setShowGameFullScreenButton = (): SetShowGameFullScreenButtonAction => ({
  type: casinoActionTypes.SET_SHOW_GAME_FULL_SCREEN_BUTTON,
});

export const setHideGameFullScreenButton = (): SetHideGameFullScreenButtonAction => ({
  type: casinoActionTypes.SET_HIDE_GAME_FULL_SCREEN_BUTTON,
});

export const toggleGameFullScreen = (): ToggleGameFullScreenAction => ({
  type: casinoActionTypes.TOGGLE_GAME_FULL_SCREEN,
});