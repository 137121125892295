export const CASINO_URL = `${process.env.NEXT_PUBLIC_LOBBY_URL}/api/v1/casino`;
export const CASINO_DATA_SOURCE = "casino";
export const LIVE_CASINO_DATA_SOURCE = "live-casino";
export const LIVE_CASINO_POPULAR_GAMES_DATA_SOURCE = "live-casino-popular";
export const CASINO_POPULAR_GAMES_DATA_SOURCE = "casino-popular";
export const CASINO_JACKPOTS_DATA_SOURCE = "casino-jackpots";

//EGT JACKPOTS NAMES
export const JACKPOTS_EGT: { [key: string]: string } = {
  spades: "levelIV",
  hearts: "levelIII",
  diamonds: "levelII",
  clubs: "levelI",
};

//EGT JACKPOTS NAMES
export const JACKPOTS_EQ_EGT: { [key: string]: string } = {
  platinum: "levelIV",
  gold: "levelIII",
  silver: "levelII",
  bronze: "levelI",
};

export const LIVE_CASINO_SPECIAL_GROUPS: { [key: string]: string } = {
  "/casino/live": "for-you",
};

export const CASINO_SPECIAL_GROUPS: { [key: string]: string } = {
  "/casino": "for-you",
  "/casino/favorites": "favorites",
  "/casino/recent-games": "recent-games",
  "/casino/all-games": "all-games",
};

export const vendorFields = [
  "id",
  "displayName",
  "name",
  "slug",
  "isSubVendor",
];
export const subVendorFields = ["id", "name"];

export const vendorExpand = ["logo"];
export const singleVendorFields = [
  "id",
  "displayName",
  "games(*)",
  "subVendorGames(*)",
  "name",
  "slug",
  "isSubVendor",
];
export const singleVendorExpandFields = ["logo", "games"];
export const singleSubVendorFields = [
  "id",
  "name",
  "identity",
  "games(*)",
  "logo",
];

export const categoryFields = ["id", "name", "games"];

export const gameFields = [
  "id",
  "name",
  "backgroundImageUrl",
  "hasFunMode",
  "subVendor(*)",
  "subVendorId",
  "gameLaunchId",
  "productCode",
  "segmentName",
  "vendor(*)",
];

export const singleGameFields = [
  "id",
  "name",
  "backgroundImageUrl",
  "hasFunMode",
  "subVendor(*)",
  "subVendorId",
  "gameLaunchId",
  "productCode",
  "segmentName",
  "vendor(*)",
];

export const gamesExpand = ["vendor", "subVendor", "primaryThumbnail", "featuredThumbnail"];

export const singleGamesExpand = [
  "vendor",
  "categories",
  "featuredThumbnail",
  "primaryThumbnail",
  "subVendor",
];
export const casinoGroupFields = [
  "id",
  "name",
  "slug",
  "showInLobby",
  "games(*)",
  "icon(*)",
  "image(*)",
  "showFeaturedThumbnail",
  "featuredThumbnailWidth",
  "featuredThumbnailHeight",
  "numberOfRows",
  "numberOfGamesPerRow",
];
export const casinoGroupExpand = [
  "games(vendor,featuredThumbnail,primaryThumbnail,subVendor)",
];

export const groupFields = ["id", "name", "games(*)", "icon(*)", "image(*)"];

export const groupExpand = ["games(vendor)"];

export const platform = "PC";

export const groupGameFields = [
  "href",
  "id",
  "name",
  "launchUrl",
  "backgroundImageUrl",
  "popularity",
  "isNew",
  "width",
  "height",
  "hasFunMode",
  "hasAnonymousFunMode",
  "thumbnail",
  "defaultThumbnail",
  "type",
  "logo",
  "slug",
  "isVIPTable",
  "vendor",
  "subVendor",
  "details",
  "primaryThumbnail",
  "featuredThumbnail",
];

export const MAX_GAMES_PER_PAGE = 100;

export const DEFAULT_ENTRY_PER_LOAD_LATEST_BETS = 10;

export const MAX_GAME_COUNT_ON_GAME_SWIPER = 100;

export const ALL_GAMES_SLUG = "all-games";