export enum loginMenuScreens {
  LOGIN = "LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  PASSWORD_CONFIRMATION = "PASSWORD_CONFIRMATION",
  VERIFY_EMAIL_INPUT = "VERIFY_EMAIL_INPUT",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  LOGIN_MENU_DATA = "LOGIN_MENU_DATA",
  UPDATE_PROFILE_WITHOUT_BONUS = "UPDATE_PROFILE_WITHOUT_BONUS"
}

export enum menuStatus {
  OPEN = "open",
  CLOSED = "closed",
}

export enum menuActionTypes {
  OPEN_LOGIN_MENU = "OPEN_LOGIN_MENU",
  CLOSE_LOGIN_MENU = "CLOSE_LOGIN_MENU",
  SET_LOGIN_MENU_SCREEN = "SET_LOGIN_MENU_SCREEN",
  OPEN_NAV_MENU = "OPEN_NAV_MENU",
  CLOSE_NAV_MENU = "CLOSE_NAV_MENU",
  CLOSE_ALL_MENUS = "CLOSE_ALL_MENUS",
  OPEN_SEARCH_MENU = "OPEN_SEARCH_MENU",
  CLOSE_SEARCH_MENU = "CLOSE_SEARCH_MENU",
  OPEN_UPDATE_PROFILE_MENU = "OPEN_UPDATE_PROFILE_MENU",
  CLOSE_UPDATE_PROFILE_MENU = "CLOSE_UPDATE_PROFILE_MENU",
}

export type OpenLoginMenuAction = {
  type: menuActionTypes.OPEN_LOGIN_MENU;
};

export type CloseLoginMenuAction = {
  type: menuActionTypes.CLOSE_LOGIN_MENU;
};

export type SetLoginMenuScreenAction = {
  type: menuActionTypes.SET_LOGIN_MENU_SCREEN;
  payload: loginMenuScreens;
};

export type OpenNavMenuAction = {
  type: menuActionTypes.OPEN_NAV_MENU;
};
export type OpenSearchMenuAction = {
  type: menuActionTypes.OPEN_SEARCH_MENU;
};
export type CloseSearchMenuAction = {
  type: menuActionTypes.CLOSE_SEARCH_MENU;
};

export type OpenUpdateProfileMenuAction = {
  type: menuActionTypes.OPEN_UPDATE_PROFILE_MENU;
};
export type CloseUpdateProfileMenuAction = {
  type: menuActionTypes.CLOSE_UPDATE_PROFILE_MENU;
};

export type CloseNavMenuAction = {
  type: menuActionTypes.CLOSE_NAV_MENU;
};

export type CloseAllMenusAction = {
  type: menuActionTypes.CLOSE_ALL_MENUS;
};

export type MenuActions =
  | OpenLoginMenuAction
  | CloseLoginMenuAction
  | SetLoginMenuScreenAction
  | OpenNavMenuAction
  | CloseNavMenuAction
  | OpenSearchMenuAction
  | CloseSearchMenuAction
  | OpenUpdateProfileMenuAction
  | CloseUpdateProfileMenuAction
  | CloseAllMenusAction;

export type MenusState = {
  loginMenuStatus: menuStatus | null;
  loginMenuScreen: loginMenuScreens;
  navMenuStatus: menuStatus | null;
  searchMenuStatus: menuStatus | null;
  updateProfileMenuStatus: menuStatus | null;
};
