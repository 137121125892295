import { RootState } from "@/fe-core/meta/types/root";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { SessionState } from "@/fe-core/meta/types/session";
import {
  ISessionViewModel,
  IAnonymousSessionCurrencyViewModel,
} from "@/fe-core/meta/interfaces/session";
import {OK_STATUS, OMEGA_STATUS_TYPES} from "@/fe-core/helpers/general";

export const sessionSelector = ({ session }: RootState): SessionState =>
  session;

export const sessionDataSelector = (
  state: RootState
): ISessionViewModel | null => {
  const { data } = sessionSelector(state);

  return data;
};

export const sessionCurrencySelector = (
  state: RootState
): IAnonymousSessionCurrencyViewModel => {
  const { currency="" } = sessionDataSelector(state) || {};

  return currency;
};

export const isUserAuthenticatedSelector = (state: RootState): boolean => {
  const session = sessionSelector(state);
  return session?.authenticate?.status?.success;
};

export const requireEmailActivation = (state: RootState): boolean => {
  const data = sessionDataSelector(state);
  const isAuthenticated = isUserAuthenticatedSelector(state);

  if (isAuthenticated) {
    if (data && !data.isEmailVerified) {
      return true;
    }
  }

  return false;
};

export const sessionLoginSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { login } = sessionSelector(state);

  return login;
};

export const isFirstLoginSelector = (
  state: RootState
): boolean => {
  const { isFirstLogin } = sessionSelector(state);
  return isFirstLogin;
};

export const sessionPrepareLoginSelector = (
    state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { prepareLogin } = sessionSelector(state);

  return prepareLogin;
};

export const sessionPrepareLoginPendingSelector = (state: RootState): boolean => {
  const { pending } = sessionPrepareLoginSelector(state);

  return pending;
};

export const sessionPrepareLoginDataSelector = (
    state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { data } = sessionPrepareLoginSelector(state);

  return data;
};

export const sessionPrepareLoginStatusSelector = (
    state: RootState
): IStatus | null => {
  const { status } = sessionPrepareLoginSelector(state);

  return status;
};

export const sessionLoginPendingSelector = (state: RootState): boolean => {
  const { pending } = sessionLoginSelector(state);

  return pending;
};

export const sessionLoginStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = sessionLoginSelector(state);

  return status;
};

export const sessionAuthenticateSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null, isUserIdentified: boolean | null } => {
  const { authenticate } = sessionSelector(state);

  return authenticate;
};

export const sessionAuthenticatePendingSelector = (
  state: RootState
): boolean => {
  const { pending } = sessionAuthenticateSelector(state);

  return pending;
};

export const sessionCeSessionSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null; ceSessionId: string | null } => {
  const { ceSession } = sessionSelector(state);

  return ceSession;
};

export const sessionCeSessionSelectorPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = sessionCeSessionSelector(state);

  return pending;
};

export const sessionAuthenticateStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = sessionAuthenticateSelector(state);

  return status;
};

export const sessionIsUserIdentifiedSelector = (
  state: RootState
): boolean | null => {
  const { isUserIdentified } = sessionAuthenticateSelector(state);
  return isUserIdentified;
};

export const sessionLogoutSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { logout } = sessionSelector(state);

  return logout;
};

export const sessionLogoutPendingSelector = (state: RootState): boolean => {
  const { pending } = sessionLogoutSelector(state);

  return pending;
};

export const sessionLogoutStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = sessionLogoutSelector(state);

  return status;
};

export const sessionPendingSelector = (state: RootState): boolean =>
  sessionLoginPendingSelector(state) ||
  sessionAuthenticatePendingSelector(state) ||
  sessionLogoutPendingSelector(state);
