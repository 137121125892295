export enum localStorageKeys {
  FIRST_SELECTION = "first-selection",
  FIRST_SELECTION_DATE = "first-selection-date",
  PREV_PATH = "prev_path",
  SEARCH_HISTORY = "search-history",
  IDENTIFIED = "identified",
  IS_DRAWER_OPEN = "is-drawer-open",
  IS_LOGGED_IN = "is-logged-in",
  USER_DATA = "user-data",
  PROFILE_DATA = "profile-data",
  BONUS_DATA = "bonus-data",
}
