import {
  ICasinoVendorDetailsPayload,
  ICasinoVendorMoreGamesPayload,
  ICasinoVendorViewModel,
  ICasinoVendorsPayload,
  IVendorDetailsViewModel,
  IVendorsRequestPayload,
} from "@/fe-core/meta/interfaces/casinoVendors";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetVendorDetailsFailureAction,
  GetVendorDetailsRequestAction,
  GetVendorDetailsSuccessAction,
  CasinoVendorPayload,
  GetCasinoVendorMoreGamesFailureAction,
  GetCasinoVendorMoreGamesRequestAction,
  GetCasinoVendorMoreGamesSuccessAction,
  GetSingleCasinoVendorFailureAction,
  GetSingleCasinoVendorRequestAction,
  GetSingleCasinoVendorSuccessAction,
  GetVendorsFailureAction,
  GetVendorsRequestAction,
  GetVendorsSuccessAction,
  SingleCasinoVendorPayload,
  casinoVendorsActionTypes,
  
  GetSubCasinoVendorMoreGamesFailureAction,
  GetSubCasinoVendorMoreGamesRequestAction,
  GetSubCasinoVendorMoreGamesSuccessAction,
  GetSubSingleCasinoVendorFailureAction,
  GetSubSingleCasinoVendorRequestAction,
  GetSubSingleCasinoVendorSuccessAction,
  GetSubVendorsFailureAction,
  GetSubVendorsRequestAction,
  GetSubVendorsSuccessAction,
} from "@/fe-core/meta/types/casinoVendors";


export const getVendorDetailsRequest = (
  payload: ICasinoVendorDetailsPayload
): GetVendorDetailsRequestAction => ({
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_REQUEST,
  payload,
});

export const GetVendorDetailFailure = (
  payload: IErrorPayload
): GetVendorDetailsFailureAction => ({
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_FAILURE,
  payload,
});

export const getVendorDetailsSuccess = (
  payload: IVendorDetailsViewModel
): GetVendorDetailsSuccessAction => ({
  type: casinoVendorsActionTypes.GET_VENDOR_DETAILS_SUCCESS,
  payload,
});

export const getVendorsRequest = (
  payload: IVendorsRequestPayload
): GetVendorsRequestAction => ({
  type: casinoVendorsActionTypes.GET_VENDORS_REQUEST,
  payload,
});

export const getVendorsSuccess = (
  payload: ICasinoVendorsPayload
): GetVendorsSuccessAction => ({
  type: casinoVendorsActionTypes.GET_VENDORS_SUCCESS,
  payload,
});

export const getVendorsFailure = (
  payload: IErrorPayload
): GetVendorsFailureAction => ({
  type: casinoVendorsActionTypes.GET_VENDORS_FAILURE,
  payload,
});

export const getSingleCasinoVendorRequest = (
  payload: SingleCasinoVendorPayload
): GetSingleCasinoVendorRequestAction => ({
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_REQUEST,
  payload,
});

export const getSingleCasinoVendorSuccess = (
  payload: ICasinoVendorViewModel
): GetSingleCasinoVendorSuccessAction => ({
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_SUCCESS,
  payload,
});

export const getSingleCasinoVendorFailure = (
  payload: IErrorPayload
): GetSingleCasinoVendorFailureAction => ({
  type: casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_FAILURE,
  payload,
});


export const getCasinoVendorMoreGamesRequest = (
  payload: CasinoVendorPayload
): GetCasinoVendorMoreGamesRequestAction => ({
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_REQUEST,
  payload,
});

export const getCasinoVendorMoreGamesSuccess = (
  payload: ICasinoVendorMoreGamesPayload
): GetCasinoVendorMoreGamesSuccessAction => ({
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_SUCCESS,
  payload,
});

export const getCasinoVendorMoreGamesFailure = (
  payload: IErrorPayload
): GetCasinoVendorMoreGamesFailureAction => ({
  type: casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_FAILURE,
  payload,
});

//sub-vendor
export const getSubVendorsRequest = (
  payload: string
): GetSubVendorsRequestAction => ({
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_REQUEST,
  payload,
});

export const getSubVendorsSuccess = (
  payload: ICasinoVendorsPayload
): GetSubVendorsSuccessAction => ({
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_SUCCESS,
  payload,
});

export const getSubVendorsFailure = (
  payload: IErrorPayload
): GetSubVendorsFailureAction => ({
  type: casinoVendorsActionTypes.GET_SUB_VENDORS_FAILURE,
  payload,
});

export const getSubSingleCasinoVendorRequest = (
  payload: SingleCasinoVendorPayload
): GetSubSingleCasinoVendorRequestAction => ({
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_REQUEST,
  payload,
});

export const getSubSingleCasinoVendorSuccess = (
  payload: ICasinoVendorViewModel
): GetSubSingleCasinoVendorSuccessAction => ({
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_SUCCESS,
  payload,
});

export const getSubSingleCasinoVendorFailure = (
  payload: IErrorPayload
): GetSubSingleCasinoVendorFailureAction => ({
  type: casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_FAILURE,
  payload,
});


export const getSubCasinoVendorMoreGamesRequest = (
  payload: CasinoVendorPayload
): GetSubCasinoVendorMoreGamesRequestAction => ({
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST,
  payload,
});

export const getSubCasinoVendorMoreGamesSuccess = (
  payload: ICasinoVendorViewModel
): GetSubCasinoVendorMoreGamesSuccessAction => ({
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_SUCCESS,
  payload,
});

export const getSubCasinoVendorMoreGamesFailure = (
  payload: IErrorPayload
): GetSubCasinoVendorMoreGamesFailureAction => ({
  type: casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_FAILURE,
  payload,
});
