import { IErrorPayload, IStatus } from '../interfaces/root'
import {
  GameTooltip,
  ICasinoCategoriesPayload,
  ICasinoCategoryViewModel,
  ICasinoGamesPayload,
  ICasinoGameViewModel,
  ICasinoGroupViewModel,
  ICasinoLoadMoreGamesSuccessPayload,
  IMobileGamePlayModalDetailsModel,
} from '../interfaces/casino'

export enum casinoActionTypes {
  GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE",
  GET_GAMES_REQUEST = "GET_GAMES_REQUEST",
  GET_GAMES_SUCCESS = "GET_GAMES_SUCCESS",
  GET_GAMES_FAILURE = "GET_GAMES_FAILURE",
  GET_MORE_GAMES_REQUEST = "GET_MORE_GAMES_REQUEST",
  GET_SINGLE_GAME_REQUEST = "GET_SINGLE_GAME_REQUEST",
  GET_SINGLE_GAME_SUCCESS = "GET_SINGLE_GAME_SUCCESS",
  GET_SINGLE_GAME_FAILURE = "GET_SINGLE_GAME_FAILURE",

  GET_CASINO_ALL_GAMES_REQUEST = "GET_CASINO_ALL_GAMES_REQUEST",
  GET_CASINO_ALL_GAMES_SUCCESS = "GET_CASINO_ALL_GAMES_SUCCESS",
  GET_CASINO_ALL_GAMES_FAILURE = "GET_CASINO_ALL_GAMES_FAILURE",

  GET_CASINO_GROUPS_REQUEST = "GET_CASINO_GROUPS_REQUEST",
  GET_CASINO_GROUPS_SUCCESS = "GET_CASINO_GROUPS_SUCCESS",
  GET_CASINO_GROUPS_FAILURE = "GET_CASINO_GROUPS_FAILURE",
  SET_ALL_GAMES_GROUP = "SET_ALL_GAMES_GROUP",
  GET_CASINO_POPULAR_GAMES_GROUP_REQUEST = "GET_CASINO_POPULAR_GAMES_GROUP_REQUEST",
  GET_CASINO_POPULAR_GAMES_GROUP_SUCCESS = "GET_CASINO_POPULAR_GAMES_GROUP_SUCCESS",
  GET_CASINO_POPULAR_GAMES_GROUP_FAILURE = "GET_CASINO_POPULAR_GAMES_GROUP_FAILURE",
  GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST = "GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST",
  GET_LIVE_CASINO_POPULAR_GAMES_GROUP_SUCCESS = "GET_LIVE_CASINO_POPULAR_GAMES_GROUP_SUCCESS",
  GET_LIVE_CASINO_POPULAR_GAMES_GROUP_FAILURE = "GET_LIVE_CASINO_POPULAR_GAMES_GROUP_FAILURE",
  GET_CASINO_AUDIT_GROUP_REQUEST = "GET_CASINO_AUDIT_GROUP_REQUEST",
  GET_CASINO_AUDIT_GROUP_SUCCESS = "GET_CASINO_AUDIT_GROUP_SUCCESS",
  GET_CASINO_AUDIT_GROUP_FAILURE = "GET_CASINO_AUDIT_GROUP_FAILURE",
  GET_CASINO_MORE_GAMES_GROUPS_REQUEST = "GET_CASINO_MORE_GAMES_GROUPS_REQUEST",
  GET_CASINO_MORE_GAMES_GROUPS_SUCCESS = "GET_CASINO_MORE_GAMES_GROUPS_SUCCESS",
  GET_CASINO_MORE_GAMES_GROUPS_FAILURE = "GET_CASINO_MORE_GAMES_GROUPS_FAILURE",
  GET_LIVE_CASINO_GROUPS_REQUEST = "GET_LIVE_CASINO_GROUPS_REQUEST",
  GET_LIVE_CASINO_GROUPS_SUCCESS = "GET_LIVE_CASINO_GROUPS_SUCCESS",
  GET_LIVE_CASINO_GROUPS_FAILURE = "GET_LIVE_CASINO_GROUPS_FAILURE",
  GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST = "GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST",
  GET_LIVE_CASINO_MORE_GAMES_GROUPS_SUCCESS = "GET_LIVE_CASINO_MORE_GAMES_GROUPS_SUCCESS",
  GET_LIVE_CASINO_MORE_GAMES_GROUPS_FAILURE = "GET_LIVE_CASINO_MORE_GAMES_GROUPS_FAILURE",
  SET_GAME_CARD_TOOLTIP = "SET_GAME_CARD_TOOLTIP",
  SET_MOBILE_GAME_PLAY_MODAL_DETAILS = "SET_MOBILE_GAME_PLAY_MODAL_DETAILS",
  SET_SHOW_GAME_ALTER_MODAL = "SET_SHOW_GAME_ALTER_MODAL",
  SET_HIDE_GAME_ALTER_MODAL = "SET_HIDE_GAME_ALTER_MODAL",

  //home datasource
  GET_CASINO_HOME_GROUPS_REQUEST = "GET_CASINO_HOME_GROUPS_REQUEST",
  GET_CASINO_HOME_GROUPS_SUCCESS = "GET_CASINO_HOME_GROUPS_SUCCESS",
  GET_CASINO_HOME_GROUPS_FAILURE = "GET_CASINO_HOME_GROUPS_FAILURE",

  SET_SHOW_GAME_FULL_SCREEN_BUTTON = "SET_SHOW_GAME_FULL_SCREEN_BUTTON",
  SET_HIDE_GAME_FULL_SCREEN_BUTTON = "SET_HIDE_GAME_FULL_SCREEN_BUTTON",

  TOGGLE_GAME_FULL_SCREEN = "TOGGLE_GAME_FULL_SCREEN",
}

export type SetShowGameAlterModalAction = {
  type: casinoActionTypes.SET_SHOW_GAME_ALTER_MODAL;
  payload: SetShowGameAlterModalPayload
};

export type SetHideGameAlterModalAction = {
  type: casinoActionTypes.SET_HIDE_GAME_ALTER_MODAL;
};

export type GetCategoriesRequestAction = {
  type: casinoActionTypes.GET_CATEGORIES_REQUEST
}

export type GetCategoriesSuccessAction = {
  type: casinoActionTypes.GET_CATEGORIES_SUCCESS
  payload: ICasinoCategoriesPayload
}

export type GetCategoriesFailureAction = {
  type: casinoActionTypes.GET_CATEGORIES_FAILURE
  payload: IErrorPayload
}

export type GetGamesRequestAction = {
  type: casinoActionTypes.GET_GAMES_REQUEST
  payload: CasinoGamesPayload
}

export type GetMoreGamesRequestAction = {
  type: casinoActionTypes.GET_MORE_GAMES_REQUEST
  payload: CasinoGamesPayload
}

export type GetSingleGameRequestAction = {
  type: casinoActionTypes.GET_SINGLE_GAME_REQUEST
  payload: CasinoSingleGamePayload
}

export type GetSingleGameSuccessAction = {
  type: casinoActionTypes.GET_SINGLE_GAME_SUCCESS
  payload: ICasinoGameViewModel
}

export type GetSingleGameFailureAction = {
  type: casinoActionTypes.GET_SINGLE_GAME_FAILURE
  payload: IErrorPayload
}

export type GetGamesSuccessAction = {
  type: casinoActionTypes.GET_GAMES_SUCCESS
  payload: ICasinoGamesPayload
}

export type GetGamesFailureAction = {
  type: casinoActionTypes.GET_GAMES_FAILURE
  payload: IErrorPayload
}

export type GetCasinoGroupsRequestAction = {
  type: casinoActionTypes.GET_CASINO_GROUPS_REQUEST
  payload: CasinoGroupsPayload
}

export type GetCasinoGroupsSuccessAction = {
  type: casinoActionTypes.GET_CASINO_GROUPS_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetCasinoGroupsFailureAction = {
  type: casinoActionTypes.GET_CASINO_GROUPS_FAILURE
  payload: IErrorPayload
}

export type GetCasinoPopularGamesGroupRequestAction = {
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_REQUEST
  payload: CasinoGroupsPayload
}

export type GetCasinoPopularGamesGroupSuccessAction = {
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetCasinoPopularGamesGroupFailureAction = {
  type: casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_FAILURE
  payload: IErrorPayload
}

export type GetLiveCasinoPopularGamesGroupRequestAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST
  payload: CasinoGroupsPayload
}

export type GetLiveCasinoPopularGamesGroupSuccessAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetLiveCasinoPopularGamesGroupFailureAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_FAILURE
  payload: IErrorPayload
}

export type GetCasinoAuditGroupRequestAction = {
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_REQUEST
  payload: CasinoGroupsPayload
}

export type GetCasinoAuditGroupSuccessAction = {
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetCasinoAuditGroupFailureAction = {
  type: casinoActionTypes.GET_CASINO_AUDIT_GROUP_FAILURE
  payload: IErrorPayload
}

export type GetCasinoMoreGamesGroupsRequestAction = {
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST
  payload: CasinoMoreGamesGroupsPayload
}

export type GetCasinoMoreGamesGroupsSuccessAction = {
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_SUCCESS;
  payload: ICasinoLoadMoreGamesSuccessPayload;
};

export type GetCasinoMoreGamesGroupsFailureAction = {
  type: casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_FAILURE
  payload: IErrorPayload
}

export type GetLiveCasinoMoreGamesGroupsRequestAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST
  payload: CasinoMoreGamesGroupsPayload
}

export type GetLiveCasinoMoreGamesGroupsSuccessAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_SUCCESS;
  payload: ICasinoLoadMoreGamesSuccessPayload;
};

export type GetLiveCasinoMoreGamesGroupsFailureAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_FAILURE
  payload: IErrorPayload
}

export type GetLiveCasinoGroupsRequestAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_REQUEST
  payload: CasinoGroupsPayload
}

export type GetLiveCasinoGroupsSuccessAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetLiveCasinoGroupsFailureAction = {
  type: casinoActionTypes.GET_LIVE_CASINO_GROUPS_FAILURE
  payload: IErrorPayload
}

export type SetGameCardTooltipAction = {
  type: casinoActionTypes.SET_GAME_CARD_TOOLTIP
  payload: GameTooltip | null
}

export type SetMobileGamePlayModalDetailsAction = {
  type: casinoActionTypes.SET_MOBILE_GAME_PLAY_MODAL_DETAILS
  payload: IMobileGamePlayModalDetailsModel
}

//home datasource
export type GetCasinoHomeGroupsRequestAction = {
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_REQUEST
  payload: CasinoGroupsPayload
}

export type GetCasinoHomeGroupsSuccessAction = {
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetCasinoHomeGroupsFailureAction = {
  type: casinoActionTypes.GET_CASINO_HOME_GROUPS_FAILURE
  payload: IErrorPayload
}

export type GetCasinoAllGamesRequestAction = {
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_REQUEST;
  payload: CasinoAllGamesPayload;
};

export type GetCasinoAllGamesSuccessAction = {
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_SUCCESS;
  payload: ICasinoGamesPayload
}

export type GetCasinoAllGamesFailureAction = {
  type: casinoActionTypes.GET_CASINO_ALL_GAMES_FAILURE;
  payload: IErrorPayload
}

export type SetCasinoAllGamesGroupAction = {
  type: casinoActionTypes.SET_ALL_GAMES_GROUP;
  payload: ICasinoGamesPayload
}

export type SetShowGameFullScreenButtonAction = {
  type: casinoActionTypes.SET_SHOW_GAME_FULL_SCREEN_BUTTON;
}
export type SetHideGameFullScreenButtonAction = {
  type: casinoActionTypes.SET_HIDE_GAME_FULL_SCREEN_BUTTON;
}

export type ToggleGameFullScreenAction = {
  type: casinoActionTypes.TOGGLE_GAME_FULL_SCREEN;
}

export type CasinoActions =
  | GetCategoriesRequestAction
  | GetCategoriesSuccessAction
  | GetCategoriesFailureAction
  | GetGamesRequestAction
  | GetGamesSuccessAction
  | GetGamesFailureAction
  | GetMoreGamesRequestAction
  | GetSingleGameRequestAction
  | GetSingleGameFailureAction
  | GetSingleGameSuccessAction
  | GetCasinoGroupsRequestAction
  | GetCasinoGroupsSuccessAction
  | GetCasinoGroupsFailureAction
  | GetCasinoPopularGamesGroupRequestAction
  | GetCasinoPopularGamesGroupSuccessAction
  | GetCasinoPopularGamesGroupFailureAction
  | GetLiveCasinoPopularGamesGroupRequestAction
  | GetLiveCasinoPopularGamesGroupFailureAction
  | GetLiveCasinoPopularGamesGroupSuccessAction
  | GetCasinoAuditGroupRequestAction
  | GetCasinoAuditGroupSuccessAction
  | GetCasinoAuditGroupFailureAction
  | GetLiveCasinoGroupsRequestAction
  | GetLiveCasinoGroupsSuccessAction
  | GetLiveCasinoGroupsFailureAction
  | GetCasinoMoreGamesGroupsRequestAction
  | GetCasinoMoreGamesGroupsSuccessAction
  | GetCasinoMoreGamesGroupsFailureAction
  | GetLiveCasinoMoreGamesGroupsRequestAction
  | GetLiveCasinoMoreGamesGroupsSuccessAction
  | GetLiveCasinoMoreGamesGroupsFailureAction
  | SetGameCardTooltipAction
  | SetMobileGamePlayModalDetailsAction
  | SetShowGameAlterModalAction
  | SetHideGameAlterModalAction
  | GetCasinoHomeGroupsRequestAction
  | GetCasinoHomeGroupsSuccessAction
  | GetCasinoHomeGroupsFailureAction
  | GetCasinoAllGamesRequestAction
  | GetCasinoAllGamesSuccessAction
  | GetCasinoAllGamesFailureAction
  | SetCasinoAllGamesGroupAction
  | SetShowGameFullScreenButtonAction
  | SetHideGameFullScreenButtonAction
  | ToggleGameFullScreenAction;

export type CasinoState = {
  pending: boolean
  // vendors: ICasinoVendorViewModel[] | null
  categories: ICasinoCategoryViewModel[] | null
  games: ICasinoGameViewModel[]
  singleGame: ICasinoGameViewModel | null
  totalCount: number
  filters: string[]
  gameCardTooltip: GameTooltip | null
  mobileGamePlayModalDetails: IMobileGamePlayModalDetailsModel
  isShowGameAlterModal: boolean
  alterGameHasFunMode: boolean
  singleGamePending: boolean
  casinoAllGames: {
    data: ICasinoGamesPayload | null,
    pending: boolean,
    moreGamesPending: boolean,
    status: IStatus | null
  }
  isShowGameFullScreenButton: boolean;
  isShowGameInFullScreen: boolean;
}

export type CasinoGroupsState = {
  casinoGroups: {
    pending: boolean
    err: string
    groups: ICasinoGroupViewModel[] | null
    moreGamesLoading: boolean
  }
  casinoHomeGroups: {
    pending: boolean
    err: string
    groups: ICasinoGroupViewModel[] | null
  }
  popularGroups: {
    pending: boolean
    err: string
    popularGamesGroup: ICasinoGroupViewModel[] | null
  }
  popularLiveGroups: {
    pending: boolean
    err: string
    popularLiveGamesGroup: ICasinoGroupViewModel[] | null
  }
  auditGroups: {
    pending: boolean
    err: string
    auditGamesGroup: ICasinoGroupViewModel[] | null
  }
}

export type LiveCasinoGroupsState = {
  pending: boolean;
  err: string;
  groups: ICasinoGroupViewModel[] | null;
  moreGamesLoading: boolean;
};

export type CasinoPagination = {
  offset: number
  limit: number
}

export type CasinoGamesPayload = {
  filters: string[]
  pagination: CasinoPagination
  platform?: string
};

export type SetShowGameAlterModalPayload = {
  hasFunMode: boolean
}

export type CasinoSingleGamePayload = {
  slug: String
};

export type CasinoCategoriesPayload = {
  platform?: string;
};

export type CasinoGroupsPayload = {
  dataSource: string
  platform?: string
  pagination?: CasinoPagination
}

export type CasinoMoreGamesGroupsPayload = {
  dataSource: string
  slug: string
  pagination: CasinoPagination
  platform?: string
}

export type CasinoVendorMoreGamesPayload = {
  dataSource: string
  id: string
  pagination: CasinoPagination
}

export type CasinoAllGamesPayload = {
  pagination?: CasinoPagination
  platform?: string
}